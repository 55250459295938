import { computed } from '@vue/composition-api'
import i18n from '@/libs/i18n'

export default function usePlansList() {

  /**
   * Property that defines the plans list headers.
   */
  const tableColumns = computed(() => {
    return [
      {
        key: 'name',
        label: i18n.t('admin.plans.headers.plan'),
        sortable: true,
      },
      {
        key: 'description',
        label: i18n.t('admin.plans.headers.name'),
        sortable: false,
      },
      {
        key: 'unit_amount',
        label: i18n.t('admin.plans.headers.price'),
        sortable: true,
      },
      {
        key: 'type',
        label: i18n.t('admin.plans.headers.type'),
        sortable: true,
      },
      {
        key: 'metadata.allowedSellers',
        label: i18n.t('admin.plans.headers.sellers'),
        sortable: true,
      },
      {
        key: 'metadata.allowedMeasurements',
        label: i18n.t('admin.plans.headers.measurements'),
        sortable: true,
      },
      {
        key: 'active',
        label: i18n.t('admin.plans.headers.active'),
        sortable: true,
      },
    ]
  })

  return {
    tableColumns
  }
}