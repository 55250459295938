<template>
  <b-card>
    <div class="m-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('admin.plans.show') }}</label>

          <v-select
            v-model="perPage"
            class="per-page-selector d-inline-block mx-50"
            :options="perPageOptions"
            :clearable="false"
          />

          <label>{{ $t('admin.plans.entries') }}</label>
        </b-col>

        <!-- Search -->
        <b-col cols="8">
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="search"
              class="d-inline-block"
              :placeholder="$t('admin.plans.searchBy')"
            />
            <b-button
              class="ml-1"
              variant="outline-primary"
              @click="
                  $router.push({
                    name: 'plan-edit',
                    params: { id: 'new' },
                  })
                "
            >
              <span class="text-nowrap">{{
                $t('admin.plans.createPlan')
              }}</span>
            </b-button>

            <b-button
              class="ml-1"
              variant="primary"
              @click="redirectToStripe()"
            >
              <span class="text-nowrap">{{
                $t('admin.plans.goToStripe')
              }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      :fields="tableColumns"
      :items="filteredPlans"
      responsive
      show-empty
      primary-key="id"
      :empty-text="$t('admin.plans.noEntries')"
      class="position-relative"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      :busy="$store.state.plan.loadingPlans"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <!-- Table Loading -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" small />

          <strong class="ml-1">{{ $t('admin.plans.loading') }}</strong>
        </div>
      </template>

      <!-- Name -->
      <template #cell(name)="data">
        <b-badge :variant="data.item.name.variant ? data.item.name.variant : data.item.label.variant ">
          {{ data.item.metadata ? data.item.name.label : data.item.label.label  }}
        </b-badge>
      </template>

      <!-- Description -->
      <template #cell(description)="data">
          {{ data.item.metadata ?  data.item.metadata.pricingName : data.item.name  }}
      </template>

      <!-- Price -->
      <template #cell(unit_amount)="data">
        {{ data.item.unit_amount? formatPrice(data.item.unit_amount) : '-' }}
      </template>

      <!-- Recurring -->
      <template #cell(type)="data">
        {{ data.item.recurring ? 'Stripe' : 'Lensxpert' }}
      </template>

      <!-- Sellers -->
      <template #cell(metadata.allowedSellers)="data">
        {{ data.item.metadata ? data.item.metadata.allowedSellers : data.item.sellers}}
      </template>

      <!-- Measurements -->
      <template #cell(metadata.allowedMeasurements)="data">
        {{ data.item.metadata ? data.item.metadata.allowedMeasurements : data.item.measurements}}
      </template>

      <!-- Active -->
      <template #cell(active)="data">
        <b-badge :variant="data.item.active == true ? 'success' : 'danger'">
          {{
            data.item.active != true
              ? '-'
              : true
              ? $t('admin.plans.headers.yesActive')
              : $t('admin.plans.headers.noActive')
          }}
        </b-badge>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">
            {{ $t('admin.plans.totalOf') }} {{ totalPlans }}
            {{ $t('admin.plans.entries') }}
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            :total-rows="totalPlans"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BTooltip,
  BRow,
  BCol,
  BFormInput,
  BSpinner,
  BPagination,
  BBadge,
} from 'bootstrap-vue'
import planStoreModule from '@/views/admin/plans/planStoreModule'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import usePlansList from './usePlansList'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BTooltip,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BPagination,
    BBadge,

    vSelect,
  },
  data() {
    return {
      // tableColumns: [
      //   {
      //     key: 'name',
      //     label: this.$i18n.t('admin.plans.headers.plan'),
      //     sortable: true,
      //   },
      //   {
      //     key: 'description',
      //     label: this.$i18n.t('admin.plans.headers.name'),
      //     sortable: false,
      //   },
      //   {
      //     key: 'unit_amount',
      //     label: this.$i18n.t('admin.plans.headers.price'),
      //     sortable: true,
      //   },
      //   {
      //     key: 'recurring',
      //     label: this.$i18n.t('admin.plans.headers.recurring'),
      //     sortable: true,
      //   },
      //   {
      //     key: 'metadata.allowedSellers',
      //     label: this.$i18n.t('admin.plans.headers.sellers'),
      //     sortable: true,
      //   },
      //   {
      //     key: 'metadata.allowedMeasurements',
      //     label: this.$i18n.t('admin.plans.headers.measurements'),
      //     sortable: true,
      //   },
      //   {
      //     key: 'active',
      //     label: this.$i18n.t('admin.plans.headers.active'),
      //     sortable: true,
      //   },
      // ],
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      currentPage: 1,
      search: '',
      sortBy: 'id',
      isSortDirDesc: false,
    }
  },
  methods: {
    redirectToStripe() {
      window.open('https://dashboard.stripe.com/test/products?active=true')
    },
    formatPrice(unit_amount) {
      return `R$ ${unit_amount / 100}`
    },
    getRecurringInterval(recurring) {
      return `${recurring.interval_count}x - ${recurring.interval}`
    },
  },
  computed: {
    filteredPlans() {
      if (this.plans) {
        return this.plans.filter(
          (el) => {
            if(el.metadata !== undefined) {
              return el.metadata.pricingName?.toLowerCase().includes(this.search.toLowerCase())
            } else {
              return el.name.toLowerCase().includes(this.search.toLowerCase())
            }
          }
        )
      } else return []
    },
    totalPlans() {
      return this.filteredPlans.length
    },
    plans() {
    const data = this.$store.state.plan.plans || []
    const data_lensxpert = this.$store.state.plan.plans_lensxpert || []

      const p = []
      data.forEach((plan) => {
        plan.prices.forEach((price) => {
          let variant = 'dark'
          if (plan.metadata.type === 'software-partner') variant = 'primary'
          else if (plan.metadata.type === 'laboratory') variant = 'warning'
          else if (plan.metadata.type === 'optic') variant = 'info'

          const o = Object.assign(
            { name: { label: plan.name, variant } },
            price,
          )
          p.push(o)
        })
      })

      data_lensxpert.forEach((plan)=>{
        let variant = 'dark'
        if (plan.type === 'Software Partner' ) variant = 'primary'
        else if (plan.type === 'Laboratory') variant = 'warning'
        else if (plan.type === 'Optic') variant = 'info'
        const o = Object.assign(
            { label: { label: plan.type, variant, name: plan.name } },
            plan
          )
          p.push(o)
      })
      return p
    },
  },
  setup() {
    const PLAN_APP_STORE_MODULE_NAME = 'plan'

    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
    })

    store.dispatch('plan/fetchPlans')
    store.dispatch('plan/fetchPlansLensxpert')

    const { tableColumns } = usePlansList()

    return {
      tableColumns,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
